import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import App from './App';
import common_en from './translations/en/common.json';
import common_fr from './translations/fr/common.json';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'fr',
    resources: {
        en: {
            common: common_en,
        },
        fr: {
            common: common_fr,
        },
    },
});

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </React.StrictMode>,
);
