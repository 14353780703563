import React from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';

export default function Footer() {
    return null;

    const { t } = useTranslation('common');
    return (
        <div className="footerWrapper">
            <p className="copyright">
                © Praxedo 2023 -
                <a className="legal" href="https://www.praxedo.com/mentions-legales/">{t('terms')}</a>
            </p>
        </div>
    );
}
