import {Steps} from "antd";
import React from "react";
import "./Progress.css"
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export default function Progress(props) {
    const {t} = useTranslation('common');
    let internalStep = props.step;
    let items = [
        {
            title: t('progress.information'),
            icon: props.step >= 1 ?  <FontAwesomeIcon icon="check" style={{width: 16, height: 16, color: '#000'}} /> : undefined,
        },
        {
            title: t('progress.booking'),
            icon: props.step >= 2 ?  <FontAwesomeIcon icon="check" style={{width: 16, height: 16, color: '#000'}} /> : undefined,
        },
        {
            title: t('progress.confirmation'),
            icon: props.step >= 3 ?  <FontAwesomeIcon icon="check" style={{width: 16, height: 16, color: '#000'}} /> : undefined,
        }
    ];

    if (props.hideFirstStep) {
        items = items.slice(1);
        internalStep = internalStep - 1;
    }
    return (
        <div className={`progressWrapper ${props.hideFirstStep ? 'progressWrapperNoFirst' : ''}`}>
            <Steps
                responsive={false}
                current={internalStep}
                labelPlacement="vertical"
                items={items}
            />
        </div>
    )
}
