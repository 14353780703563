import React from 'react';
import './App.css';
import './fontAwesome';
import {
    BrowserRouter, Navigate, Route, Routes, useParams,
} from 'react-router-dom';
import MainCustomerView from './pages/endCustomerPortal/MainCustomerView';

function RedirectWithUUID() {
    const { uuid } = useParams();
    return <Navigate to={`/portal/${uuid}`} replace />;
}

function App() {
    return (
        <div className="wrapper">
            <BrowserRouter>
                <Routes>
                    <Route path="/p/:uuid" element={<RedirectWithUUID />} />
                    <Route path="/portal/:uuid" element={<MainCustomerView />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
