// Fontawesome Icons.
import {
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faCalendarCheck,
    faCheck,
    faCircleCheck,
    faCircleInfo,
    faLanguage,
    faPencil,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';
import {Icon, library} from '@fortawesome/fontawesome-svg-core';

const icons = [
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faCheck,
    faCalendarCheck,
    faCircleCheck,
    faLanguage,
    faPencil,
    faXmark,
    faCircleInfo
] as Icon[];

/**
 * Create a library to use icons in all React components.
 * Import your icons and add it to the library to make them available.
 */
library.add(...icons);
