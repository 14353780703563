import React, { useEffect, useState } from 'react';
import { Collapse, Form, Input } from 'antd';
import './Summary.css';
import { useTranslation } from 'react-i18next';

// const { TextArea } = Input;

export default function Summary(props) {
    const [startDate = new Date(), setStartDate] = useState<string>();
    const [endDate = new Date(), setEndDate] = useState<number>();

    const { Panel } = Collapse;
    const { t } = useTranslation('common');
    const currentWorkOrder = props.workOrder;
    useEffect(() => setDate());

    function setDate() {
        if (props.selectedSlot === undefined) {
            setStartDate(currentWorkOrder.scheduledStartDate);
            setEndDate(currentWorkOrder.scheduledEndDate);
        } else {
            setStartDate(props.selectedSlot.start);
            setEndDate(props.selectedSlot.end);
        }
    }

    return (
        <div className="summaryWrapper">
            <Form layout="vertical" disabled>
                {/* <Form.Item label={t('form.type')}> */}
                {/*    <Input value={currentWorkOrder.type.name}></Input> */}
                {/* </Form.Item> */}
                {/* <Form.Item label={t('form.description')}> */}
                {/*    <Input value={currentWorkOrder.description}></Input> */}
                {/* </Form.Item> */}
                <Form.Item label={t('form.name')}>
                    <Input value={currentWorkOrder.customer.name} />
                </Form.Item>
                <Form.Item label={t('form.phone')}>
                    <Input value={props.newPhoneNumber != null ? props.newPhoneNumber : currentWorkOrder.mobileNumber} />
                </Form.Item>
                <Form.Item label={t('form.email')}>
                    <Input value={props.newEmailAddress != null ? props.newEmailAddress : currentWorkOrder.emailAddress} />
                </Form.Item>
                <Form.Item label={t('form.address')}>
                    <Input value={currentWorkOrder.address} />
                </Form.Item>
                <Form.Item label={t('form.zip')}>
                    <Input value={currentWorkOrder.zipcode} />
                </Form.Item>
                <Form.Item label={t('form.city')}>
                    <Input value={currentWorkOrder.city} />
                </Form.Item>
                <Form.Item className="summaryCollapse" label={t('form.slot')}>
                    <Collapse size="small" expandIconPosition="end">
                        <Panel
                            key={1}
                            header={new Date(startDate).toLocaleDateString(t('date.locale'), {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            }).charAt(0).toUpperCase() + new Date(startDate).toLocaleDateString(t('date.locale'), {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            }).substring(1)}
                        >
                            <div className="selectedSlot">
                                {new Date(startDate).toLocaleTimeString(t('date.locale'), {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                                {' '}
                                -
                                {' '}
                                {new Date(new Date(endDate).getTime() + currentWorkOrder.authorizedDelay * 60000).toLocaleTimeString(t('date.locale'), {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </div>
                        </Panel>
                    </Collapse>
                </Form.Item>
                {/* <Form.Item label={t('form.additionalInfo')}> */}
                {/*    <TextArea value={props.additionalInfo} rows={4} /> */}
                {/* </Form.Item> */}

            </Form>
        </div>
    );
}
