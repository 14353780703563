import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './BookingConfirmation.css';

export default function BookingConfirmation(props) {
    const { t } = useTranslation('common');
    return (
        <div className="confirmation">
            <div className="confirmation-appointment">
                {/* <h2>{props.workOrder.type.name} &nbsp;<FontAwesomeIcon icon="circle-check" style={{color: "#38C92C",}}/></h2> */}
                <h3 className="confirmation-appointment-title">{t('booking.appointment.title')}</h3>
                {/* <div className="confirmation-appointment-item"> */}
                {/*    <div className="confirmation-appointment-item-label">{t('booking.appointment.technician')}</div> */}
                {/*    <div className="confirmation-appointment-item-value confirmation-appointment-item-value-technician">XXX{props.workOrder.technicianName}</div> */}
                {/* </div> */}
                <div className="confirmation-appointment-item">
                    <div className="confirmation-appointment-item-label">{t('booking.appointment.appointmentDate')}</div>
                    <div className="confirmation-appointment-item-value">
                        {new Date(props.workOrder.scheduledStartDate).toLocaleString(
                            t('date.locale'),
                            {
                                weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric',
                            },
                        ).charAt(0).toUpperCase() + new Date(props.workOrder.scheduledStartDate).toLocaleString(
                            t('date.locale'),
                            {
                                weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric',
                            },
                        ).substring(1)}
                        {props.withAppointmentRange && (
                            <>
                                <br />
                                {t('booking.appointment.range', {
                                    start: new Date(props.workOrder.scheduledStartDate).toLocaleString(
                                        t('date.locale'),
                                        { hour: '2-digit', minute: '2-digit' }
                                    ),
                                    end: new Date(new Date(props.workOrder.scheduledEndDate).getTime() + props.workOrder.authorizedDelay * 60000).toLocaleTimeString(
                                        t('date.locale'),
                                        { hour: '2-digit', minute: '2-digit' }
                                    ),
                                })}
                            </>
                        )}
                    </div>
                </div>
                {(props.withCalendarButton || props.withRescheduleButton) && (
                    <div className="confirmation-appointment-buttons">
                        {props.withCalendarButton && <Button className="confirmation-appointment-button confirmation-appointment-button-save-calendar" onClick={props.saveCalendar}>{t('main.buttons.save')}</Button>}
                        {props.withRescheduleButton && <Button className="confirmation-appointment-button confirmation-appointment-button-reschedule" onClick={props.rescheduleAppointment}>{t('main.buttons.edit')}</Button>}
                    </div>
                )}
            </div>
            <div className="confirmation-appointment">
                <h3 className="confirmation-appointment-title">{t('booking.recap.title')}</h3>
                <div className="confirmation-appointment-item">
                    <div className="confirmation-appointment-item-label">{t('booking.recap.address')}</div>
                    <div className="confirmation-appointment-item-value">
                        <div>{props.workOrder.customer.name}</div>
                        <div>{`${props.workOrder.address} ${props.workOrder.zipcode}, ${props.workOrder.city}`}</div>
                    </div>
                </div>

                {(props.newPhoneNumber || props.workOrder.customer?.phone || props.newEmailAddress || props.workOrder.customer?.email)
                    && (
                        <div className="confirmation-appointment-item">
                            <div className="confirmation-appointment-item-label">{t('booking.recap.contact')}</div>
                            <div className="confirmation-appointment-item-value">
                                {props.newPhoneNumber ? (
                                    <div>{props.newPhoneNumber.replace(/(.{2})/g, '$& ')}</div>
                                ) : (
                                    <>
                                        {props.workOrder.customer.phone ? (
                                            <div>{props.workOrder.customer.phone.replace(/(.{2})/g, '$& ')}</div>
                                        ) : null}
                                    </>
                                )}
                                {props.newEmailAddress ? (
                                    <div>{props.newEmailAddress}</div>
                                ) : ((
                                    <>
                                        {props.workOrder.customer.email ? (
                                            <div>{props.workOrder.customer.email}</div>
                                        ) : null}
                                    </>
                                ))}
                            </div>
                        </div>
                    )}

                {props.additionalInfo
                    && (
                        <div className="confirmation-appointment-item">
                            <div className="confirmation-appointment-item-label">{t('booking.recap.additionalInfo')}</div>
                            <div className="confirmation-appointment-item-value">{props.additionalInfo}</div>
                        </div>
                    )}

            </div>
        </div>
    );
}
